import request from '@/utils/request'
// 列表
export function queryDataList (data) {
  return request({
    url: '/admin/map-list',
    method: 'post',
    data,
  })
}
// 详情
export function queryDataDetail (data) {
  return request({
    url: '/tree-map/info',
    method: 'post',
    data,
  })
}
// 保存
export function saveDataItem (data) {
  return request({
    url: '/tree-map/save',
    method: 'post',
    data,
  })
}
// 删除
export function delDataItem (data) {
  return request({
    url: '/tree-map/delete',
    method: 'post',
    data,
  })
}


